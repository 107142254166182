import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import AOS from 'aos';
import { fetchHCTemplates } from '../../HeadCountAPI/head_count_api';
import { HC_API } from "../../config";
import '../../assets/css/hc_custom.css';

const TemplatesList = () => {
    const [templates, setTemplates] = useState([]);

    const ref = useRef();

    const fetchTemplates = async () => {
        try {
            const templatesResponse = await fetchHCTemplates();
            setTemplates(templatesResponse);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    };

    useEffect(() => {

        if (templates.length > 0) {
            console.log("Templates: ", templates);
            if (templates.length === 1) {
                setTimeout(function () {
                    ref.current.click();
                }, 1000);
            }
        }

    }, [templates]);

    useEffect(() => {
        const interval = setInterval(fetchTemplates, HC_API.POLLING_INTERVAL);
        fetchTemplates();


        return () => clearInterval(interval);
    }, []);


    document.title = 'Templates List - BlueEra Softech';

    return (
        <React.Fragment>
            <div className="page-content" style={{ marginTop: '60px' }}>
                <Container fluid>
                    <div className="Wrapper" style={templates.length > 6 ? { height: 'auto' } : null}>
                        <div className=" w-100" >
                            <Row>
                                <Col xs={12}>
                                    <div className="page-title-box align-items-center justify-content-between text-center text-uppercase">
                                        <h1 className="mb-sm-0" style={{ color: 'var(--headcount-theme-titles-color)' }}>TEMPLATES</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {templates.map((template, index) => {

                                    // console.log("Template: ", template);

                                    if (template.active) {
                                        return (
                                            <Col key={template.id} lg={6} xxl={4} style={{
                                                marginLeft: index === 0 && templates.length > 1 ? 'auto' : '',
                                                marginRight: index === templates.length - 1 && templates.length > 1 ? 'auto' : '',
                                                display: 'flex', paddingTop: '12px', paddingBottom: '12px'
                                            }}>
                                                <Link to={`/views-list/${template.id}`} ref={ref} style={{ width: '100%' }}>
                                                    <Card className="card-body text-center table" style={{ border: '2px solid var(--headcount-theme-color)', padding: '1.5rem', height: '100%' }}>
                                                        <div className="avatar-lg mx-auto mb-3">
                                                            <div className="avatar-title bg-success-subtle text-success fs-32 rounded iconAvtarClass">
                                                                <i className="ri-building-4-line" style={{ fontSize: '60px', color: 'var(--headcount-theme-color)' }}></i>
                                                            </div>
                                                        </div>
                                                        <h2 className="" style={{ fontSize: 'var(--font-size-default)' }}>{template.name}</h2>
                                                        {/*<p className="card-text text-muted">{template.description}</p>*/}
                                                        <button className="btn btn-sm btn-success" style={{ fontSize: '32px', width: '250px', margin: 'auto auto 0px auto' }}>SELECT</button>
                                                    </Card>
                                                </Link>
                                            </Col>
                                        );
                                    }
                                }
                                )}
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TemplatesList;
