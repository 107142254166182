module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://api-node.themesbrand.website",
  },
  HC_API: {
    API_URL: "https://headcountbackend.blueerasoftech.com",
    //Indofil local IP
    // API_URL: "http://172.16.1.18:58001",
    //Indorama local IP
    // API_URL: "http://10.127.75.211:58001",
    BEARER_TOKEN: "8THR9ygDmOL0TenRUO79dpu8",
    POLLING_INTERVAL: 60000,
  }
};

