import React from 'react';

//constants
import { layoutModeTypes } from "../../Components/constants/layout";
import '../../assets/css/hc_custom.css';


const LightDark = ({ layoutMode, onChangeLayoutMode }) => {

    const mode = layoutMode === layoutModeTypes['DARKMODE'] ? layoutModeTypes['LIGHTMODE'] : layoutModeTypes['DARKMODE'];
    const updateThemeColor = (mode) => {
        const root = document.documentElement;
        if (mode === layoutModeTypes['DARKMODE']) {
            root.style.setProperty('--headcount-theme-titles-color', 'var(--headcount-theme-titles-color-dark-mode)');
            
            root.style.setProperty('--scrollable-table-header-color','var(--scrollable-table-header-color-dark)');
        } else {
            
            root.style.setProperty('--headcount-theme-titles-color', 'var(--headcount-theme-titles-color-light-mode)');
            root.style.setProperty('--scrollable-table-header-color','var(--scrollable-table-header-color-light)');
        }
    };
    return (
        <div className="ms-4 header-item d-none d-sm-flex">
            <button
                onClick={() => {
                    onChangeLayoutMode(mode);
                    updateThemeColor(mode);
                }}                type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                <i className='bx bx-moon fs-36'></i>
            </button>
        </div>
    );
};

export default LightDark;
