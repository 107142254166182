import React, { useState } from 'react';
import './assets/scss/themes.scss';
import Route from './Routes';
import fakeBackend from "./helpers/AuthType/fakeBackend";

fakeBackend();


export const ViewDetailContext = React.createContext();

function App() {
    const [templateDetail, setTemplateDetail] = useState({
        viewTypes: [],
        template: {}
    });
    const [lastApiResponseTime, setLastApiResponseTime] = useState(null);

    const [data, setData] = useState([]);
    const [block1TeamData, setBlock1TeamData] = useState([]);
    const [block2TeamData, setBlock2TeamData] = useState([]);
    const [block3TeamData, setBlock3TeamData] = useState([]);

    const [isBlock1TeamEnabled, setIsBlock1TeamEnabled] = useState(false);
    const [isBlock2TeamEnabled, setIsBlock2TeamEnabled] = useState(false);
    const [isBlock3TeamEnabled, setIsBlock3TeamEnabled] = useState(false);
    const [trueCount, setTrueCount] = useState(0);

    const [rawShiftNames, setRawShiftNames] = useState([])
    const [shiftCounts, setShiftCounts] = useState([]);

    const [customBlock1Label, setCustomBlock1Label] = useState('')
    const [customBlock2Label, setCustomBlock2Label] = useState('')
    const [customBlock1InCount, setCustomBlock1InCount] = useState('')
    const [customBlock2InCount, setCustomBlock2InCount] = useState('')

    const [customBlock1TeamData, setCustomBlock1TeamData] = useState('')
    const [customBlock2TeamData, setCustomBlock2TeamData] = useState('')

    const [customBlock1Image,setCustomBlock1Image] = useState(null)
    const [customBlock2Image,setCustomBlock2Image] = useState(null)

    const [orgs, setOrgs] = useState([]);
    const [totalOrgHeadCount, setTotalOrgHeadCount] = useState('');
    const [expandedRows, setExpandedRows] = useState([]);
    const [showChildTable, setShowChildTable] = useState(false);


    const [cats, setCats] = useState([]);
    const [totalCatHeadCount, setTotalCatHeadCount] = useState('');

    const [depts, setDepts] = useState([]);
    const [totalDeptHeadCount, setTotalDeptHeadCount] = useState('');

    const [ertMembersPresent, setErtMembersPresent] = useState([]);
    const [totalErtMembersAvailableState, setTotalErtMembersAvailable] = useState('');

    const [logsData, setLogsData] = useState([])
    const [HCLogsData, setHCLogsData] = useState([])

    return (
        <React.Fragment>
            <ViewDetailContext.Provider
                value={{
                    templateDetail, setTemplateDetail,

                    lastApiResponseTime, setLastApiResponseTime,

                    data, setData,
                    block1TeamData, setBlock1TeamData,
                    block2TeamData, setBlock2TeamData,
                    block3TeamData, setBlock3TeamData,
                    isBlock1TeamEnabled, setIsBlock1TeamEnabled,
                    isBlock2TeamEnabled, setIsBlock2TeamEnabled,
                    isBlock3TeamEnabled, setIsBlock3TeamEnabled,
                    trueCount, setTrueCount,

                    orgs, setOrgs,
                    totalOrgHeadCount, setTotalOrgHeadCount,
                    expandedRows, setExpandedRows,
                    showChildTable, setShowChildTable,

                    cats, setCats,
                    totalCatHeadCount, setTotalCatHeadCount,

                    depts, setDepts,
                    totalDeptHeadCount, setTotalDeptHeadCount,
                    rawShiftNames, setRawShiftNames,
                    shiftCounts, setShiftCounts,
                    logsData, setLogsData,
                    HCLogsData, setHCLogsData,

                    customBlock1Label, setCustomBlock1Label,
                    customBlock2Label, setCustomBlock2Label,
                    customBlock1InCount,setCustomBlock1InCount,
                    customBlock2InCount,setCustomBlock2InCount,
                    customBlock1TeamData,setCustomBlock1TeamData,
                    customBlock2TeamData,setCustomBlock2TeamData,
                    customBlock1Image,setCustomBlock1Image,
                    customBlock2Image,setCustomBlock2Image


                }}
            >
                <Route />
            </ViewDetailContext.Provider>
        </React.Fragment>
    );
}

export default App;
