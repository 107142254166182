import React, { useContext, useEffect, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { fetchHCTemplateDetail } from "../../HeadCountAPI/head_count_api";
import { HC_API } from "../../config";
import { ViewDetailContext } from '../../App';

const WebAppsDropdown = () => {
    const { id, type } = useParams();
    const [isWebAppDropdown, setIsWebAppDropdown] = useState(false);
    const { templateDetail, setTemplateDetail } = useContext(ViewDetailContext);

    const fetchTemplateDetail = async (id) => {
        try {
            if (!id) {
                return;
            }
            const templateDetail = await fetchHCTemplateDetail(id);
            setTemplateDetail(templateDetail);
        } catch (error) {
            console.error('Error fetching Data WebAppsDropdown :', error);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => fetchTemplateDetail(id), HC_API.POLLING_INTERVAL);
        fetchTemplateDetail(id);
        return () => clearInterval(interval);
    }, [id]);

    const { viewTypes = [] } = templateDetail || {};

    const toggleWebAppDropdown = () => {
        setIsWebAppDropdown(!isWebAppDropdown);
    };

    const closeDropdown = () => {
        setIsWebAppDropdown(false);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isWebAppDropdown} toggle={toggleWebAppDropdown} className="topbar-head-dropdown ms-4 header-item">
                <DropdownToggle tag="button" type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-collection fs-36'></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end" style={{ border: '2px solid var(--headcount-theme-color)', fontSize: '28px' }}>
                    <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border" style={{ backgroundColor: 'var(--headcount-theme-color)' }}>
                        <Row className="align-items-center">
                            <Col>
                                <h3 className="m-0 fw-semibold" style={{ color: 'white' }}> Select View </h3>
                            </Col>
                        </Row>
                    </div>

                    <div className="p-0" style={{ maxHeight: "calc(100vh - 220px)", overflowY: "scroll" }}>
                        {viewTypes.map((item) => (
                            <div key={item.type} className="row g-0">
                                <Col>
                                    <Link
                                        className="dropdown-icon-item"
                                        style={{ backgroundColor: item.type === type ? "#eff2f7" : "white", color: 'var(--headcount-theme-color)' }}
                                        to={`/view-detail/${id}/${item.type}`}
                                        onClick={closeDropdown}
                                    >
                                        <span style={{ textAlign: 'left', padding: '0px 15px' }}>{item.name}</span>
                                    </Link>
                                </Col>
                            </div>
                        ))}
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default WebAppsDropdown;
