import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AOS from "aos";
import {fetchHCTemplateDetail, fetchHCTemplates} from "../../HeadCountAPI/head_count_api";
import {HC_API} from "../../config";

const ViewsList = () => {

    const { id } = useParams();

    const [templateDetail, setTemplateDetail] = useState({
        viewTypes: [],
        template: {}
    });

    const fetchTemplateDetail = async (id) => {
        try {
            if (!id) {
                return;
            }
            const templateDetailResponse = await fetchHCTemplateDetail(id);
            // console.log('Fetched template detail response:', templateDetailResponse);

            setTemplateDetail(templateDetailResponse);
        } catch (error) {
            console.error('Error fetching template detail:', error);
        }
    };

    const { viewTypes, template } = templateDetail;


    useEffect(() => {
        const interval = setInterval(() => fetchTemplateDetail(id), HC_API.POLLING_INTERVAL);
        fetchTemplateDetail(id);

        return () => clearInterval(interval);
    }, [id]);


    useEffect(() => {
        AOS.init({
            easing: 'ease-out-back',
            duration: 3000,
            anchorPlacement: 'top-bottom',
        });
    }, []);


    document.title ="Views List - BlueEra Softech";

    return (
        <React.Fragment>
            <div className="page-content" style={viewTypes.length > 4 ? { marginTop: '100px' } : { marginTop: '100px' }}>
                <Container fluid>
                    {/*<BreadCrumb title="Views List" pageTitle="Home" />*/}
                    <div className="customBlockWrapperViewList" style={viewTypes.length > 4 ? { height: 'auto' } : null}>
                        <div className="customBlock w-100" >
                            <Row>
                                <Col xs={12}>
                                    <div className="page-title-box align-items-center justify-content-between text-center text-uppercase">
                                        <h1 className="mb-sm-0" style={{color:'var(--headcount-theme-titles-color)'}}>{template.name}</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Card style={{backgroundColor: 'transparent', boxShadow: 'none'}}>
                                        {/*<CardHeader>*/}
                                        {/*    <div className="text-center">*/}
                                        {/*        <h2 className="mb-0">Select View</h2>*/}
                                        {/*    </div>*/}
                                        {/*</CardHeader>*/}

                                        <CardBody>
                                            <div className="live-preview">
                                                <div>
                                                    <Row>

                                                        {viewTypes.map((item, index) => {

                                                                    return (
                                                                        <Col key={item.type} lg={4} xxl={3} style={{
                                                                            marginLeft: index === 0 && templateDetail.length > 1 ? 'auto' : '',
                                                                            marginRight: index === templateDetail.length - 1 && templateDetail.length > 1 ? 'auto' : '',
                                                                            display: 'flex',paddingTop: '12px',paddingBottom: '12px'
                                                                        }}>
                                                                            <Link to={`/view-detail/${id}/${item.type}`} style={{width: '100%'}}>
                                                                                <div className="card card-body text-center table"  style={{ border: '2px solid var(--headcount-theme-color)',padding:'1.5rem', height: '100%' }}>
                                                                                    <div className="avatar-lg mx-auto mb-3">
                                                                                    <div className="avatar-title bg-success-subtle text-success fs-32 rounded iconAvtarClass">
                                                                                        <i className="ri-team-line" style={{fontSize:'60px',color:'var(--headcount-theme-color)'}}></i>
                                                                                    </div>
                                                                                    </div>
                                                                                        <h2 className="" style={{ fontSize: 'var(--font-size-default)' }}>{item.name}</h2>
                                                                                            {/*<p className="card-text text-muted"></p>*/}
                                                                                        <button className="btn btn-sm btn-success" style={{fontSize: '32px',width:'250px',margin:'auto auto 0px auto'}}>SELECT</button>
                                                                                    </div>
                                                                            </Link>
                                                                        </Col>
                                                                    );

                                                            }
                                                        )}

                                                    </Row>

                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ViewsList;
