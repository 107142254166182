import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Table, Input, InputGroup, InputGroupText } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import AOS from 'aos';
import { ViewDetailContext } from '../../App'; // Adjust import based on your actual context file path
import { fetchHCAll, fetchHCLogs, fetchHCORGHeadCountData, fetchCustomBlock, fetchHCCATHeadCountData, fetchHCDeptHeadCountData, fetchHCHeadCountData, fetchHCBlock1, fetchHCBlock2, fetchHCBlock3 } from '../../HeadCountAPI/head_count_api';
import { HC_API } from '../../config';
import SearchOption from '../../Components/Common/SearchOption';
import '../../assets/css/hc_custom.css';
import Pagination from '../../Components/Common/Pagination';


import { array } from 'yup';
import { jobCandidates } from '../../common/data/appsJobs';


const moment = require('moment'); // For setting Time & Date format


const ViewDetail = () => {
    const { type, id } = useParams();
    const location = useLocation();
    const {
        data,
        setData,
        block1TeamData,
        setBlock1TeamData,
        block2TeamData,
        setBlock2TeamData,
        block3TeamData,
        setBlock3TeamData,
        isBlock1TeamEnabled,
        setIsBlock1TeamEnabled,
        isBlock2TeamEnabled,
        setIsBlock2TeamEnabled,
        isBlock3TeamEnabled,
        setIsBlock3TeamEnabled,
        trueCount,
        setTrueCount,

        orgs,
        setOrgs,
        totalOrgHeadCount,
        setTotalOrgHeadCount,
        showChildTable, setShowChildTable,

        cats,
        setCats,
        totalCatHeadCount,
        setTotalCatHeadCount,

        depts,
        setDepts,
        totalDeptHeadCount,
        setTotalDeptHeadCount,

        lastApiResponseTime, setLastApiResponseTime,
        rawShiftNames, setRawShiftNames,
        shiftCounts, setShiftCounts,
        logsData, setLogsData,
        HCLogsData, setHCLogsData,

        customBlock1Label, setCustomBlock1Label,
        customBlock2Label, setCustomBlock2Label,
        customBlock1InCount, setCustomBlock1InCount,
        customBlock2InCount, setCustomBlock2InCount,
        customBlock1TeamData, setCustomBlock1TeamData,
        customBlock2TeamData, setCustomBlock2TeamData,

        customBlock1Image, setCustomBlock1Image,
        customBlock2Image, setCustomBlock2Image

    } = useContext(ViewDetailContext);

    const [currentPage, setCurrentPage] = useState(1);
    const [apiSearch, setApiSearch] = useState("")
    const [apiFilteredData, setApiFilteredData] = useState(data.headcount_logs)

    const handleSearch = async (event) => {
        setApiSearch(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {

            try {
                //   const response = await fetch(`https://headcountbackend.blueerasoftech.com/api/hc_templates/headcount-logs/search?q=${apiSearch}`);
                const response = await fetchHCLogs(id, type, 0, perPageData, indexOfFirst, apiSearch)
                const data = await response.logsData;
                setApiFilteredData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }

        };

        fetchData();
    }, [apiSearch]); // Re-run the effect whenever 'query' changes

    //pagination
    const perPageData = 10;
    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;

    const handleRowClick = () => {
        setTimeout(() => {
            window.open(`/view-detail/1/headcountlogs`);

        }, 100); // 100 milliseconds delay
    };

    //Code for type = 'all'
    const fetchAll = async () => {
        try {
            const { data,
                block1TeamData,
                block2TeamData,
                block3TeamData,
                trueCount,
                is_block_1_team_enabled,
                is_block_2_team_enabled,
                is_block_3_team_enabled } = await fetchHCAll(id, type, 0);

            console.log("After Getting Data from HCAll")
            setLastApiResponseTime(new Date());
            setData(data);
            setBlock1TeamData(block1TeamData);
            setBlock2TeamData(block2TeamData);
            setBlock3TeamData(block3TeamData);
            setIsBlock1TeamEnabled(is_block_1_team_enabled);
            setIsBlock2TeamEnabled(is_block_2_team_enabled);
            setIsBlock3TeamEnabled(is_block_3_team_enabled);
            setTrueCount(trueCount);
            // console.log("block1TeamData : ",block1TeamData);
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };



    const enabledTeams = [
        isBlock1TeamEnabled ? { data: block1TeamData, total: data?.in_block_1_count, phoneField: data?.block_1_phone_field, name: data?.block_1_label } : null,
        isBlock2TeamEnabled ? { data: block2TeamData, total: data?.in_block_2_count, phoneField: data?.block_2_phone_field, name: data?.block_2_label } : null,
        isBlock3TeamEnabled ? { data: block3TeamData, total: data?.in_block_3_count, phoneField: data?.block_3_phone_field, name: data?.block_3_label } : null,
    ].filter(Boolean); // Filter out null values

    const shouldApplyStyle = enabledTeams.some(team => team?.total > 6);

    const enabledTeamsDummy1 = [
        {
            name_birthdayDummy: 'Head Count',
            total_birthdayDummy: 25,
            data_birthdayDummy: [
                { FullName_birthdayDummy: 'John Doe', phoneField: 'phone', phone_birthdayDummy: '123-456-7890', birthdayDummy: '1990-01-01' },
                { FullName_birthdayDummy: 'Jane Smith', phoneField: 'phone', phone_birthdayDummy: '987-654-3210', birthdayDummy: '1992-02-02' },
                { FullName_birthdayDummy: 'Jane Smith', phoneField: 'phone', phone_birthdayDummy: '987-654-3210', birthdayDummy: '1992-02-02' },

            ],
            phoneField_birthdayDummy: 'phone',
        },
        {
            name_birthdayDummy: 'First Aiders',
            total_birthdayDummy: 30,
            data_birthdayDummy: [
                { FullName_birthdayDummy: 'Alice Johnson', phoneField: 'phone', phone_birthdayDummy: '555-555-5555', birthdayDummy: '1985-03-03' },
                { FullName_birthdayDummy: 'Bob Brown', phoneField: 'phone', phone_birthdayDummy: '444-444-4444', birthdayDummy: '1988-04-04' },
                { FullName_birthdayDummy: 'Alice Johnson', phoneField: 'phone', phone_birthdayDummy: '555-555-5555', birthdayDummy: '1985-03-03' },
                { FullName_birthdayDummy: 'Bob Brown', phoneField: 'phone', phone_birthdayDummy: '444-444-4444', birthdayDummy: '1988-04-04' },
                { FullName_birthdayDummy: 'Bob Brown', phoneField: 'phone', phone_birthdayDummy: '444-444-4444', birthdayDummy: '1988-04-04' },
            ],
            phoneField_birthdayDummy: 'phone',
        },
    ];



    const trueCount_birthdayDummy = enabledTeamsDummy1.length; // Number of teams

    // const birthdayTeamCount = data.birthday_teams_2 && Array.isArray(data.birthday_teams_2) && data.birthday_teams_2.length



    const getShiftCount = (org, shift) => {

        if (data.shifts !== undefined || data.shifts.length !== 0) {

            const countKey = `${shift.toLowerCase()}_count`;
            return org[countKey] || 0; // Default to 0 if not found
        }
    };

    let totalContactorCount1 = 0
    let totalContactorCount2 = 0
    let totalContactorCount3 = 0
    let totalOthersCount = 0

    useEffect(() => {

        fetchHCLogsData();
    }, [currentPage]); // Fetch data when currentPage changes

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        console.log('Team length greater than 4:', shouldApplyStyle);
    }, [shouldApplyStyle]);
    // console.log("Team length greater than 4 : ", shouldApplyStyle);

    const countsAPIKeysName = []

    //Code for type = 'organization'
    const fetchOrgHeadCountData = async () => {
        try {
            const { orgs, totalOrgHeadCount, data } = await fetchHCORGHeadCountData(id, type, 0);
            setOrgs(orgs);
            setTotalOrgHeadCount(totalOrgHeadCount);
            setData(data);
            setLastApiResponseTime(new Date());
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };

    const childRows = orgs.filter(item => item.ORGName !== data.organization_name && item.ORGName !== 'Visitors') || [];

    data.orgs !== undefined ? childRows.forEach(item => {
        rawShiftNames !== undefined ? totalContactorCount1 = totalContactorCount1 + getShiftCount(item, rawShiftNames[0]) : null
        rawShiftNames !== undefined ? totalContactorCount2 = totalContactorCount2 + getShiftCount(item, rawShiftNames[1]) : null
        rawShiftNames !== undefined ? totalContactorCount3 = totalContactorCount3 + getShiftCount(item, rawShiftNames[2]) : null
        totalOthersCount = totalOthersCount + item.others_count

    }) : null;
    // const mainRows = orgs.filter(item => item.ORGName === data.organization_name || item.ORGName === 'Visitors');
    const mainRows = orgs.filter(item =>
        item.ORGName === data.organization_name ||
        (item.ORGName === 'Visitors' && data.is_vms_enabled)
    ) || [];

    let totalContractorCount = 0

    function addTotalCount(totalContractorCount, data) {
        // Iterate through the data array using a for loop
        for (let i = 0; i < data.length; i++) {
            const item = data[i]; // Get the current item at index i
            console.log("total addCount", totalContractorCount);
            totalContractorCount += item.count; // Add item.count to totalContractorCount
        }
        // Return the updated totalContractorCount
        return totalContractorCount;
    }

    const toggleChildTable = () => {
        setShowChildTable(!showChildTable);
    };

    //Code for type = 'category'
    const fetchCatHeadCountData = async () => {
        try {
            const { cats, totalCatHeadCount, data } = await fetchHCCATHeadCountData(id, type, 0);
            setCats(cats);
            setData(data);
            setTotalCatHeadCount(totalCatHeadCount);
            setLastApiResponseTime(new Date());
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };

    //Code for type = 'headcount_Logs'
    const fetchHCLogsData = async () => {

        try {

            const response = await fetchHCLogs(id, type, 0, perPageData, indexOfFirst, apiSearch);

            // Manually assign values from response
            const logsDataApi = response.logsData;
            const data = response.data;

            setLogsData(logsDataApi)

            type === "headcountlogs" && setData(data)
            setHCLogsData(data)
            setLastApiResponseTime(new Date());
            setApiFilteredData(logsDataApi);
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };


    const fetchCustomBlockData = async () => {

        try {

            const response = await fetchCustomBlock(id, type, 0);

            // Manually assign values from response

            console.log("CustomBlock Data", response.data)
            const data = response?.data;


            // setLogsData(logsDataApi)

            setData(data)

            if (type === "custom_2") {

                setCustomBlock2TeamData(data?.block_2_team)
                setCustomBlock2InCount(data?.in_block_2_count)
                setCustomBlock2Label(data?.block_2_label)
                setCustomBlock2Image(data?.block_2_image)
            }
            else if (type === "custom_1") {

                setCustomBlock1Image(data?.block_1_image)
                setCustomBlock1Label(data?.block_1_label)
                setCustomBlock1InCount(data?.in_block_1_count)
                setCustomBlock1TeamData(data?.block_1_team)
            }





        } catch (error) {
            console.error('Error fetching custom block 1 Data:', error);
        }
    };


    useEffect(() => {
        console.log("customBlock1Label:", customBlock1Label);
        console.log("customBlock2Label:", customBlock2Label);
        console.log("customBlock1InCount:", customBlock1InCount);
        console.log("customBlock2InCount:", customBlock2InCount);
        console.log("customBlock1TeamData:", customBlock1TeamData);
        console.log("customBlock2TeamData:", customBlock2TeamData);
        console.log("customBlock1Image (URL):", customBlock1Image);
        console.log("customBlock2Image (URL):", customBlock2Image);
    }, [
        customBlock1Label,
        customBlock2Label,
        customBlock1InCount,
        customBlock2InCount,
        customBlock1TeamData,
        customBlock2TeamData,
        customBlock1Image,
        customBlock2Image
    ]);




    const filteredCats = cats.filter(item => item.CTGName !== 'Visitors' || data.is_vms_enabled);

    useEffect(() => {
        console.log("Filtered Categories Data", filteredCats)
    }, [data.ctgs && filteredCats])


    //Code for type = 'department'
    const fetchDeptHeadCountData = async () => {
        try {
            const { depts, totalDeptHeadCount, data } = await fetchHCDeptHeadCountData(id, type, 0);
            setDepts(depts);
            setData(data);
            setTotalDeptHeadCount(totalDeptHeadCount);
            setLastApiResponseTime(new Date());
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };
    const filteredDepts = depts.filter(item => item.DepartmentName !== 'Visitors' || data.is_vms_enabled);


    const fetchHeadCountData = async () => {
        try {
            const { data } = await fetchHCHeadCountData(id, type, 0);
            setData(data);
            setLastApiResponseTime(new Date());
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };

    //Code for type = 'block_1'
    const fetchBlock1Data = async () => {
        try {
            const { data,
                block1TeamData,
                trueCount,
                is_block_1_team_enabled,
            } = await fetchHCBlock1(id, type, 0);
            setData(data);
            setBlock1TeamData(block1TeamData);
            setIsBlock1TeamEnabled(is_block_1_team_enabled);
            setTrueCount(trueCount);
            setLastApiResponseTime(new Date());
            // console.log("block1TeamData : ",block1TeamData);
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };
    const enabledBlock1Teams = [
        isBlock1TeamEnabled ? { data: block1TeamData, total: data?.in_block_1_count, phoneField: data?.block_1_phone_field, name: data?.block_1_label } : null,
    ].filter(Boolean);


    //Code for type = 'block_2'
    const fetchBlock2Data = async () => {
        try {
            const { data,
                block2TeamData,
                trueCount,
                is_block_2_team_enabled,
            } = await fetchHCBlock2(id, type, 0);
            setData(data);
            setBlock2TeamData(block2TeamData);
            setIsBlock2TeamEnabled(is_block_2_team_enabled);
            setTrueCount(trueCount);
            setLastApiResponseTime(new Date());
            // console.log("block2TeamData : ",block2TeamData);
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };
    const enabledBlock2Teams = [
        isBlock2TeamEnabled ? { data: block2TeamData, total: data?.in_block_2_count, phoneField: data?.block_2_phone_field, name: data?.block_2_label } : null,
    ].filter(Boolean);

    useEffect(() => {
        data.shifts !== 0 && handleShiftCountCalculation();
        data.shifts !== 0 && setRawShiftNames(data.shifts)

    }, [data.shifts]); // Assuming 'data' is the dependency


    const handleShiftCountCalculation = () => {

        if (data.shifts !== undefined) {

            const newShiftCounts = data.is_display_shift ? (
                data.shifts.map((shift, index) => (
                    {
                        index,
                        shift,
                        [`shiftBlock${index}`]: `${shift.toLowerCase()}_count` || 0,
                        // Add the index property
                    }))
            ) : [];

            setShiftCounts(newShiftCounts);
        }
    };

    //Code for type = 'block_3'
    const fetchBlock3Data = async () => {
        try {

            console.log("Fetching Block3 data ")
            const { data,
                block3TeamData,
                trueCount,
                is_block_3_team_enabled,
            } = await fetchHCBlock3(id, type, 0);
            setData(data);
            setBlock3TeamData(block3TeamData);
            setIsBlock3TeamEnabled(is_block_3_team_enabled);
            setTrueCount(trueCount);
            setLastApiResponseTime(new Date());

        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };
    const enabledBlock3Teams = [
        isBlock3TeamEnabled ? { data: block3TeamData, total: data?.in_block_3_count, phoneField: data?.block_3_phone_field, name: data?.block_3_label } : null,
    ].filter(Boolean);

    useEffect(() => {
        AOS.init({
            easing: 'ease-out-back',
            duration: 3000,
            anchorPlacement: 'top-bottom',
        });
    }, []);

    useEffect(() => {
        let interval;
        if (type === 'all') {
            interval = setInterval(fetchAll, HC_API.POLLING_INTERVAL);
            fetchAll();
        } else if (type === 'organization') {
            interval = setInterval(fetchOrgHeadCountData, HC_API.POLLING_INTERVAL);
            fetchOrgHeadCountData();
        } else if (type === 'category') {
            interval = setInterval(fetchCatHeadCountData, HC_API.POLLING_INTERVAL);
            fetchCatHeadCountData();
        } else if (type === 'department') {
            interval = setInterval(fetchDeptHeadCountData, HC_API.POLLING_INTERVAL);
            fetchDeptHeadCountData();
        } else if (type === 'headcount') {
            interval = setInterval(fetchHeadCountData, HC_API.POLLING_INTERVAL);
            fetchHeadCountData();
        } else if (type === 'block_1') {
            interval = setInterval(fetchBlock1Data, HC_API.POLLING_INTERVAL);
            fetchBlock1Data();
        } else if (type === 'block_2') {
            interval = setInterval(fetchBlock2Data, HC_API.POLLING_INTERVAL);
            fetchBlock2Data();
        } else if (type === 'headcountlogs') {
            interval = setInterval(fetchHCLogsData, HC_API.POLLING_INTERVAL);
            fetchHCLogsData();
        }
        else if (type === "birthday_teams_2") {

            console.log("Inside Birthdat Teams 2 Block")
            interval = setInterval(fetchAll, HC_API.POLLING_INTERVAL);
            fetchAll();
            console.log("After executing Birthday Team function")
        }

        else if (type === "custom_1") {
            console.log("Inside Custom 1 Block")
            interval = setInterval(fetchAll, HC_API.POLLING_INTERVAL);
            fetchCustomBlockData()
            setData(data);
        }

        else if (type === "custom_2") {
            console.log("Inside Custom 2 Block")
            interval = setInterval(fetchAll, HC_API.POLLING_INTERVAL);
            // fetchAll();
            // fetchCustomBlock()
            fetchCustomBlockData()
            setData(data);
        }

        else {
            interval = setInterval(fetchBlock3Data, HC_API.POLLING_INTERVAL);
            console.log("Fetching Block 3 data")
            fetchBlock3Data();
        }
        return () => clearInterval(interval);
    }, [location, id, type]);

    useEffect(() => {
        console.log("Data updated: ", data)
    }, [data])

    return (
        <React.Fragment>
            <div className="page-content" style={{ marginTop: '65px' }}>
                {type === 'all' && (
                    <Container fluid>
                        <div className="customBlockWrapper" style={shouldApplyStyle ? { height: 'auto' } : null}>
                            <div className="customBlock w-100" >
                                <Row>
                                    <Col xs={12}>
                                        {/*<div className="page-title-box align-items-center justify-content-between text-center text-uppercase">*/}
                                        {/*    <h1 className="mb-sm-0" style={{ color: 'var(--headcount-theme-color)', fontWeight: '600' }}>*/}
                                        {/*        {type === 'all' ? 'All' : ''}*/}
                                        {/*    </h1>*/}
                                        {/*</div>*/}

                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                            <CardBody style={{ padding: '0' }}>
                                                <div className="table-responsive">
                                                    <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                        <thead style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white', textTransform: 'uppercase' }}>
                                                            <tr style={{
                                                                verticalAlign: 'middle',
                                                                wordWrap: 'wrap',
                                                            }}>
                                                                <th
                                                                    scope="col"
                                                                    className="text-center"
                                                                    style={{
                                                                        padding: '15px 25px',
                                                                        width: '50%',
                                                                        borderBottomWidth: '0'
                                                                    }}>
                                                                    Total Head Count
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-center"
                                                                    style={{
                                                                        padding: '15px 25px',
                                                                        width: '50%',
                                                                        borderBottomWidth: '0'
                                                                    }}>
                                                                    {data.in_count_with_visitor}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        {data.is_vms_enabled && (
                                                            <tbody style={{ color: 'var(--headcount-theme-titles-color)', fontWeight: '600' }}>
                                                                <tr >
                                                                    <td className="text-center" style={{
                                                                        padding: '15px 25px',
                                                                        borderRight: '2px solid var(--headcount-theme-color)',
                                                                        position: 'relative',
                                                                    }}>
                                                                        EMPLOYEES
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                marginLeft: '10%',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>{data.in_count}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center" style={{
                                                                        padding: '15px 25px',
                                                                        position: 'relative',
                                                                    }}>
                                                                        VISITORS
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                marginLeft: '10%',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>{data.in_count_visitor}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    {enabledTeams && Array.isArray(enabledTeams) && enabledTeams.map((team, index) => (
                                        <Col
                                            key={index}
                                            lg={trueCount === 1 ? 12 : trueCount === 2 ? 6 : trueCount === 3 ? 4 : 12}>
                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead>
                                                                <tr
                                                                    style={{
                                                                        verticalAlign: 'middle',
                                                                        wordWrap: 'wrap',
                                                                    }}>
                                                                    <th
                                                                        scope="col"
                                                                        colSpan={2}
                                                                        className="text-wrap"
                                                                        style={{
                                                                            paddingBottom: '1.7rem',
                                                                            borderBottom: '1px solid var(--headcount-theme-color)',
                                                                            position: 'relative',
                                                                            paddingRight: '100px',
                                                                            color: 'var(--headcount-theme-titles-color)',
                                                                            textTransform: 'uppercase'
                                                                        }}>
                                                                        {team.name}
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '42%',
                                                                                right: '10px',
                                                                                transform: 'translateY(-50%)',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>
                                                                            {team.total}
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Array.isArray(team.data) && team.data.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="" style={{ whiteSpace: 'normal' }}>{item.FullName}</td>
                                                                        <td className="text-end">{item[team.phoneField]}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>

                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'organization' && (
                    <Container fluid>
                        <div className="customBlockWrapper" style={orgs.length > 7 ? { height: 'auto' } : null}>
                            <div className="customBlock w-100" >
                                <Row>
                                    <Col lg={12}>
                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                            <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                <div className="table-responsive">
                                                    <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                        <thead style={{ color: 'var(--headcount-theme-titles-color)' }}>
                                                            <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}>
                                                                <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Sr. No.</th>
                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Organization Name</th>
                                                                {data.is_display_shift && (
                                                                    <React.Fragment>
                                                                        {data.shifts && data.shifts.map((shift, index) => (
                                                                            <React.Fragment key={index}> {/* Added key prop */}
                                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{shift}
                                                                                </th>
                                                                            </React.Fragment>
                                                                        ))}
                                                                        <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{data.other_shifts_display_label}
                                                                        </th>
                                                                    </React.Fragment>
                                                                )}
                                                                <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>{data.is_display_shift ? "Total Head Count" : "Head Count"}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {mainRows.length !== 0 && mainRows.map((item, index) => {
                                                                let displayName = item.ORGName;

                                                                return (
                                                                    <tr key={index} onClick={displayName !== "Visitors" ? handleRowClick : undefined}
                                                                        className={displayName !== "Visitors" ? 'table-row-clickable' : ''}>

                                                                        <td className="text-center" style={{ padding: '0.6rem' }}>{index + 1}</td>
                                                                        <td style={{ padding: '0.6rem' }}>{displayName}</td>

                                                                        {data.is_display_shift && data.shifts !== undefined && data.shifts.map(shift => (
                                                                            <td key={shift} className="text-center" style={{ padding: '0.6rem' }}>
                                                                                {getShiftCount(item, shift) === 0 ? "-" : getShiftCount(item, shift)}
                                                                            </td>
                                                                        ))}

                                                                        {data.is_display_shift && (
                                                                            <td className="text-center" style={{ padding: '0.6rem' }}>
                                                                                {item.others_count === 0 ? "-" : item.others_count}
                                                                            </td>
                                                                        )}

                                                                        <td className="text-center" style={{ padding: '0.6rem' }}>{item.count}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            <tr key="contractors" onClick={toggleChildTable} style={{ cursor: 'pointer' }}>

                                                                <td className="text-center" style={{ padding: '0.6rem', position: 'relative' }}>
                                                                    <span
                                                                        className="text-center"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: data.is_display_shift ? '10px' : "40px",
                                                                            transform: 'translateY(-50%)',
                                                                            backgroundColor: 'var(--headcount-theme-color)',
                                                                            color: 'white',
                                                                            padding: '0px 7px',
                                                                            borderRadius: '5px'
                                                                        }}>
                                                                        <i className={showChildTable ? "ri-subtract-line" : "ri-add-line"}></i>
                                                                    </span>
                                                                    {mainRows.length + 1}</td>
                                                                <td style={{ padding: '0.6rem' }}>{data.contractors_group_display_label}</td>


                                                                {data.is_display_shift && (

                                                                    <React.Fragment>
                                                                        <td className="text-center" style={{ padding: '0.6rem' }}>
                                                                            {totalContactorCount1}
                                                                        </td>
                                                                        <td className="text-center" style={{ padding: '0.6rem' }}>
                                                                            {totalContactorCount2}
                                                                        </td>
                                                                        <td className="text-center" style={{ padding: '0.6rem' }}>
                                                                            {totalContactorCount3}
                                                                        </td>
                                                                        <td className="text-center" style={{ padding: '0.6rem' }}>{totalOthersCount === 0 || undefined ? "-" : totalOthersCount}</td>

                                                                        <td className="text-center" style={{ padding: '0.6rem' }}>{childRows.reduce((total, item) => total + item.count, 0)}</td>
                                                                    </React.Fragment>
                                                                )}
                                                                {!data.is_display_shift && (<td className="text-center" style={{ padding: '0.6rem' }}>{addTotalCount(totalContractorCount, childRows)}</td>)}

                                                            </tr>
                                                            {

                                                                showChildTable && (

                                                                    childRows.map((item, index) => {
                                                                        return (
                                                                            <tr key={`child-${index}`}>
                                                                                <td className="text-center" style={{ padding: '0.6rem' }}>
                                                                                </td>
                                                                                <td style={{ padding: '0.6rem' }}>{item.ORGName}</td>


                                                                                {data.is_display_shift && data.shifts !== undefined ? (data.shifts.map(shift => (
                                                                                    <td className="text-center" style={{ padding: '0.6rem' }}>{getShiftCount(item, shift)}</td>
                                                                                ))) : (
                                                                                    <td className="text-center" style={{ padding: '0.6rem' }}> {item.count}</td>

                                                                                )}
                                                                                {data.is_display_shift && (
                                                                                    <React.Fragment>
                                                                                        <td className="text-center" style={{ padding: '0.6rem' }}>{item.others_count}</td>
                                                                                        <td className="text-center" style={{ padding: '0.6rem' }}>{item.count}</td>

                                                                                    </React.Fragment>
                                                                                )}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                )
                                                            }
                                                        </tbody>
                                                        <tfoot className="table-light" style={{ fontWeight: '600' }}>
                                                            <tr>
                                                                <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }}></td>
                                                                <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} colSpan={data.is_display_shift && data.shifts !== undefined ? data.shifts.length + 2 : "1"}>Total Head Count</td>
                                                                <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} className="text-center">{totalOrgHeadCount}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'category' && (
                    <Container fluid>
                        <div className="customBlockWrapper" style={cats.length > 7 ? { height: 'auto' } : null}>
                            <div className="customBlock w-100" >
                                <Row>
                                    <Col lg={12}>
                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                            <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                <div className="table-responsive">
                                                    <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                        <thead style={{ color: 'var(--headcount-theme-titles-color)' }}>
                                                            <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}>
                                                                <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Sr. No.</th>
                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Categories</th>
                                                                {data.is_display_shift && (
                                                                    <React.Fragment>
                                                                        {data.shifts && data.shifts !== undefined && data.shifts.map((shift, index) => (
                                                                            <React.Fragment key={index}> {/* Added key prop */}
                                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{shift}
                                                                                </th>
                                                                            </React.Fragment>
                                                                        ))}
                                                                        <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{data.other_shifts_display_label}
                                                                        </th>
                                                                    </React.Fragment>
                                                                )}
                                                                <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>{data.is_display_shift ? "Total Head Count" : "Head Count"}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {filteredCats.map((item, index) => {
                                                                return (
                                                                    <tr key={index} onClick={item.CTGName !== "Visitors" ? handleRowClick : undefined}
                                                                        className={item.CTGName !== "Visitors" ? 'table-row-clickable' : ''}>
                                                                        <td className="text-center" style={{ padding: '1rem 0.6rem' }}>{index + 1}</td>
                                                                        <td style={{ padding: '1rem 0.6rem' }}>{item.CTGName}</td>



                                                                        {data.is_display_shift && data.shifts !== undefined ? (data.shifts.map(shift => (
                                                                            <td className="text-center" style={{ padding: '0.6rem' }}>{getShiftCount(item, shift) === 0 ? "-" : getShiftCount(item, shift)}</td>
                                                                        ))) : (
                                                                            <td className="text-center" style={{ padding: '0.6rem' }}>{item.count === 0 ? "-" : item.count}</td>

                                                                        )}

                                                                        {data.is_display_shift && data.shifts !== undefined && (
                                                                            <React.Fragment>

                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>{item.others_count}</td>
                                                                                <td className="text-center" style={{ padding: '1rem 0.6rem' }}>{item.count}</td>
                                                                            </React.Fragment>

                                                                        )}

                                                                    </tr>
                                                                );

                                                            })}
                                                        </tbody>
                                                        <tfoot className="table-light" style={{ fontWeight: '600' }}>
                                                            <tr>
                                                                <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }}></td>
                                                                <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} colSpan={data.is_display_shift ? data.shifts && data.shifts.length + 2 : 1}>Total Head Count</td>
                                                                <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} className="text-center">{totalCatHeadCount}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'department' && (
                    <Container fluid>
                        <div className="customBlockWrapper" style={depts.length > 7 ? { height: 'auto' } : null}>
                            <div className="customBlock w-100" >
                                <Row>
                                    <Col lg={12}>
                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                            <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                <div className="table-responsive">
                                                    <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                        <thead style={{ color: 'var(--headcount-theme-titles-color)' }}>
                                                            <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}>
                                                                <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Sr. No.</th>
                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Departments</th>

                                                                {data.is_display_shift && data.shifts && (
                                                                    <React.Fragment>
                                                                        {data.shifts && data.shifts.map((shift, index) => (
                                                                            <React.Fragment key={index}> {/* Added key prop */}
                                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{shift}
                                                                                </th>
                                                                            </React.Fragment>
                                                                        ))}
                                                                        <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{data.other_shifts_display_label}
                                                                        </th>
                                                                    </React.Fragment>
                                                                )}

                                                                <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>{data.is_display_shift ? "Total Head Count" : "Head Count"}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredDepts.map((item, index) => {
                                                                return (
                                                                    <tr key={index} onClick={item.DepartmentName !== "Visitors" ? handleRowClick : undefined}
                                                                        className={item.DepartmentName !== "Visitors" ? 'table-row-clickable' : ''}>
                                                                        <td className="text-center" style={{ padding: '1rem 0.6rem' }}>{index + 1}</td>
                                                                        <td style={{ padding: '1rem 0.6rem' }}>{item.DepartmentName}</td>

                                                                        {data.is_display_shift && data.shifts ? (data.shifts.map(shift => (
                                                                            <td className="text-center" style={{ padding: '0.6rem' }}>{getShiftCount(item, shift) === 0 ? "-" : getShiftCount(item, shift)}</td>
                                                                        ))) : (
                                                                            <td className="text-center" style={{ padding: '0.6rem' }}> {item.count === 0 ? "-" : item.count}</td>

                                                                        )}

                                                                        {data.is_display_shift && data.shifts && (
                                                                            <React.Fragment>

                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>{item.others_count === 0 ? "-" : item.others_count}</td>
                                                                                <td className="text-center" style={{ padding: '1rem 0.6rem' }}>{item.count === 0 ? "-" : item.count}</td>
                                                                            </React.Fragment>

                                                                        )}

                                                                    </tr>
                                                                );

                                                            })}
                                                        </tbody>
                                                        <tfoot className="table-light" style={{ fontWeight: '600' }}>
                                                            <tr>
                                                                <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }}></td>
                                                                <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} colSpan={data.is_display_shift && data.shifts ? data.shifts.length + 2 : 1}>Total Head Count</td>
                                                                <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} className="text-center">{totalDeptHeadCount}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'headcount' && (
                    <Container fluid>
                        <div className="customBlockWrapper" >
                            <div className="customBlock w-100" >
                                <Row style={{ fontSize: 'var(--table-font-size-default)' }}>
                                    <Col lg={8} style={{ margin: 'auto' }}>
                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                            <CardBody style={{ paddingBottom: '1.7rem', textAlign: 'center' }}>
                                                <div style={{ fontSize: 'var(--headcount-bigcount-font-size-default)', fontWeight: 'bold', lineHeight: '1', padding: '20px 0px' }}>{data.in_count_with_visitor}</div>
                                                <div style={{ fontSize: 'var(--headcount-title-font-size-default)', lineHeight: '1', backgroundColor: 'var(--headcount-theme-color)', color: 'white', padding: '15px 0', borderRadius: '2px', fontWeight: '500' }}>HEADCOUNT</div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4} style={{ marginLeft: 'auto' }}>
                                        <Card style={{ border: '2px solid #099885' }} className="table">
                                            <CardBody style={{ padding: '0', textAlign: 'center' }}>
                                                <div style={{ fontSize: 'var(--headcount-smallcount-font-size-default)', lineHeight: '1', padding: '50px 0px' }}>{data.hc_in_count}</div>
                                                <div className="alert alert-success" style={{ fontSize: 'var(--headcount-title-font-size-default)', lineHeight: '1', padding: '15px 0', borderWidth: '0px', borderTopWidth: '1px', borderRadius: '0', fontWeight: '500', margin: '0px' }}>IN</div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={4} style={{ marginRight: 'auto' }}>
                                        <Card style={{ border: '2px solid #CC563D' }} className="table">
                                            <CardBody style={{ padding: '0', textAlign: 'center' }}>
                                                <div style={{ fontSize: 'var(--headcount-smallcount-font-size-default)', lineHeight: '1', padding: '50px 0px' }}>{data.hc_out_count}</div>
                                                <div className="alert alert-danger" style={{ fontSize: 'var(--headcount-title-font-size-default)', lineHeight: '1', padding: '15px 0', borderWidth: '0px', borderTopWidth: '1px', borderRadius: '0', fontWeight: '500', margin: '0px' }}>OUT</div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'block_1' && (
                    <Container fluid>
                        <div className="customBlockWrapper" style={block1TeamData.length > 6 ? { height: 'auto' } : null}>
                            <div className="customBlock w-100" >
                                <Row>
                                    <Col xs={12}>
                                        {/*<div className="page-title-box align-items-center justify-content-between text-center text-uppercase">*/}
                                        {/*    <h1 className="mb-sm-0" style={{ color: 'var(--headcount-theme-color)', fontWeight: '600' }}>*/}
                                        {/*        {type === 'all' ? 'All' : ''}*/}
                                        {/*    </h1>*/}
                                        {/*</div>*/}

                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                            <CardBody style={{ padding: '0' }}>
                                                <div className="table-responsive">
                                                    <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                        <thead style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white', textTransform: 'uppercase' }}>
                                                            <tr
                                                                style={{
                                                                    verticalAlign: 'middle',
                                                                    wordWrap: 'wrap',
                                                                }}>
                                                                <th
                                                                    scope="col"
                                                                    className="text-center"
                                                                    style={{
                                                                        padding: '15px 25px',
                                                                        width: '50%',
                                                                        borderBottomWidth: '0'
                                                                    }}>
                                                                    Total Head Count
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-center"
                                                                    style={{
                                                                        padding: '15px 25px',
                                                                        width: '50%',
                                                                        borderBottomWidth: '0'
                                                                    }}>
                                                                    {data.in_count_with_visitor}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        {data.is_vms_enabled && (
                                                            <tbody style={{ color: 'var(--headcount-theme-titles-color)', fontWeight: '600' }}>
                                                                <tr >
                                                                    <td className="text-center" style={{
                                                                        padding: '15px 25px',
                                                                        borderRight: '2px solid var(--headcount-theme-color)',
                                                                        position: 'relative'
                                                                    }}>
                                                                        EMPLOYEES
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                marginLeft: '10%',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>{data.in_count}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center" style={{
                                                                        padding: '15px 25px',
                                                                        position: 'relative'
                                                                    }}>
                                                                        VISITORS
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                marginLeft: '10%',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>{data.in_count_visitor}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    {enabledBlock1Teams.map((team, index) => (
                                        <Col
                                            key={index}
                                            lg={12}>
                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead>
                                                                <tr
                                                                    style={{
                                                                        verticalAlign: 'middle',
                                                                        wordWrap: 'wrap',
                                                                    }}>
                                                                    <th
                                                                        scope="col"
                                                                        colSpan={2}
                                                                        className="text-wrap"
                                                                        style={{
                                                                            paddingBottom: '1.7rem',
                                                                            borderBottom: '1px solid var(--headcount-theme-color)',
                                                                            position: 'relative',
                                                                            paddingRight: '100px',
                                                                            color: 'var(--headcount-theme-titles-color)',
                                                                            textTransform: 'uppercase'
                                                                        }}>
                                                                        {team.name}
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '42%',
                                                                                right: '10px',
                                                                                transform: 'translateY(-50%)',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>
                                                                            {team.total}
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {team && Array.isArray(team.data) && team.data.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="" style={{ whiteSpace: 'normal' }}>{item.FullName}</td>
                                                                        <td className="text-end">{item[team.phoneField]}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'block_2' && (
                    <Container fluid>
                        <div className="customBlockWrapper" style={block2TeamData.length > 6 ? { height: 'auto' } : null}>
                            <div className="customBlock w-100" >
                                <Row>
                                    <Col xs={12}>
                                        {/*<div className="page-title-box align-items-center justify-content-between text-center text-uppercase">*/}
                                        {/*    <h1 className="mb-sm-0" style={{ color: 'var(--headcount-theme-color)', fontWeight: '600' }}>*/}
                                        {/*        {type === 'all' ? 'All' : ''}*/}
                                        {/*    </h1>*/}
                                        {/*</div>*/}

                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                            <CardBody style={{ padding: '0' }}>
                                                <div className="table-responsive">
                                                    <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                        <thead style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white', textTransform: 'uppercase' }}>
                                                            <tr
                                                                style={{
                                                                    verticalAlign: 'middle',
                                                                    wordWrap: 'wrap',
                                                                }}>
                                                                <th
                                                                    scope="col"
                                                                    className="text-center"
                                                                    style={{
                                                                        padding: '15px 25px',
                                                                        width: '50%',
                                                                        borderBottomWidth: '0'
                                                                    }}>
                                                                    Total Head Count
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-center"
                                                                    style={{
                                                                        padding: '15px 25px',
                                                                        width: '50%',
                                                                        borderBottomWidth: '0'
                                                                    }}>
                                                                    {data.in_count_with_visitor}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        {data.is_vms_enabled && (
                                                            <tbody style={{ color: 'var(--headcount-theme-titles-color)', fontWeight: '600' }}>
                                                                <tr >
                                                                    <td className="text-center" style={{
                                                                        padding: '15px 25px',
                                                                        borderRight: '2px solid var(--headcount-theme-color)',
                                                                        position: 'relative'
                                                                    }}>
                                                                        EMPLOYEES
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                marginLeft: '10%',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>{data.in_count}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center" style={{
                                                                        padding: '15px 25px',
                                                                        position: 'relative'
                                                                    }}>
                                                                        VISITORS
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                marginLeft: '10%',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>{data.in_count_visitor}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    {enabledBlock2Teams.map((team, index) => (
                                        <Col
                                            key={index}
                                            lg={12}>
                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead>
                                                                <tr
                                                                    style={{
                                                                        verticalAlign: 'middle',
                                                                        wordWrap: 'wrap',
                                                                    }}>
                                                                    <th
                                                                        scope="col"
                                                                        colSpan={2}
                                                                        className="text-wrap"
                                                                        style={{
                                                                            paddingBottom: '1.7rem',
                                                                            borderBottom: '1px solid var(--headcount-theme-color)',
                                                                            position: 'relative',
                                                                            paddingRight: '100px',
                                                                            color: 'var(--headcount-theme-titles-color)',
                                                                            textTransform: 'uppercase'
                                                                        }}>
                                                                        {team.name}
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '42%',
                                                                                right: '10px',
                                                                                transform: 'translateY(-50%)',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>
                                                                            {team.total}
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {team && Array.isArray(team.data) && team.data.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="" style={{ whiteSpace: 'normal' }}>{item.FullName}</td>
                                                                        <td className="text-end">{item[team.phoneField]}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'block_3' && (
                    <Container fluid>
                        <div className="customBlockWrapper" style={Array.isArray(block3TeamData) && block3TeamData.length > 6 ? { height: 'auto' } : null}>
                            <div className="customBlock w-100" >
                                <Row>
                                    <Col xs={12}>
                                        {/*<div className="page-title-box align-items-center justify-content-between text-center text-uppercase">*/}
                                        {/*    <h1 className="mb-sm-0" style={{ color: 'var(--headcount-theme-color)', fontWeight: '600' }}>*/}
                                        {/*        {type === 'all' ? 'All' : ''}*/}
                                        {/*    </h1>*/}
                                        {/*</div>*/}

                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                            <CardBody style={{ padding: '0' }}>
                                                <div className="table-responsive">
                                                    <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                        <thead style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white', textTransform: 'uppercase' }}>
                                                            <tr
                                                                style={{
                                                                    verticalAlign: 'middle',
                                                                    wordWrap: 'wrap',
                                                                }}>
                                                                <th
                                                                    scope="col"
                                                                    className="text-center"
                                                                    style={{
                                                                        padding: '15px 25px',
                                                                        width: '50%',
                                                                        borderBottomWidth: '0'
                                                                    }}>
                                                                    Total Head Count
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-center"
                                                                    style={{
                                                                        padding: '15px 25px',
                                                                        width: '50%',
                                                                        borderBottomWidth: '0'
                                                                    }}>
                                                                    {data.in_count_with_visitor}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        {data.is_vms_enabled && (
                                                            <tbody style={{ color: 'var(--headcount-theme-titles-color)', fontWeight: '600' }}>
                                                                <tr >
                                                                    <td className="text-center" style={{
                                                                        padding: '15px 25px',
                                                                        borderRight: '2px solid var(--headcount-theme-color)',
                                                                        position: 'relative'
                                                                    }}>
                                                                        EMPLOYEES
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                marginLeft: '10%',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>{data.in_count}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center" style={{
                                                                        padding: '15px 25px',
                                                                        position: 'relative'
                                                                    }}>
                                                                        VISITORS
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                marginLeft: '10%',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>{data.in_count_visitor}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    {enabledBlock3Teams.map((team, index) => (
                                        <Col
                                            key={index}
                                            lg={12}>
                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead>
                                                                <tr
                                                                    style={{
                                                                        verticalAlign: 'middle',
                                                                        wordWrap: 'wrap',
                                                                    }}>
                                                                    <th
                                                                        scope="col"
                                                                        colSpan={2}
                                                                        className="text-wrap"
                                                                        style={{
                                                                            paddingBottom: '1.7rem',
                                                                            borderBottom: '1px solid var(--headcount-theme-color)',
                                                                            position: 'relative',
                                                                            paddingRight: '100px',
                                                                            color: 'var(--headcount-theme-titles-color)',
                                                                            textTransform: 'uppercase'
                                                                        }}>
                                                                        {team.name}
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '42%',
                                                                                right: '10px',
                                                                                transform: 'translateY(-50%)',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px'
                                                                            }}>
                                                                            {team.total}
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {team && Array.isArray(team.data) && team.data.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="" style={{ whiteSpace: 'normal' }}>{item.FullName}</td>
                                                                        <td className="text-end">{item[team.phoneField]}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </Container>
                )}



                {type === 'headcountlogs' && (
                    <Container fluid>

                        <div className="customBlockWrapper" style={{ height: 'auto' }}>

                            <div className="customBlock w-100" >
                                <Row>
                                    <Col sm={12} md={{ size: 6, offset: 6 }} lg={{ size: 4, offset: 8 }} className='customTextInput'>
                                        <InputGroup>
                                            <InputGroupText>
                                                <i className="las la-search"></i>
                                            </InputGroupText>
                                            <Input
                                                placeholder='Search...'
                                                value={apiSearch}
                                                onChange={handleSearch}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}>
                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                            <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                <div className="table-responsive">
                                                    <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                        <thead style={{ color: 'var(--headcount-theme-titles-color)' }}>
                                                            <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}>
                                                                <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Sr.</th>
                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Id</th>
                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Full Name</th>
                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Organization</th>
                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Department</th>
                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Designation</th>
                                                                <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>In Time</th>
                                                                <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Branch</th>
                                                                <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Phone</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ paddingBottom: "50px" }}>
                                                            {apiFilteredData && Array.isArray(apiFilteredData) && apiFilteredData.length !== 0 && (
                                                                <React.Fragment>

                                                                    {apiFilteredData && apiFilteredData.map((item, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <tr>
                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                    {index + 1}
                                                                                </td>

                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                    {item.mx_user_id}
                                                                                </td>

                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                    {item.FullName !== "" ? item.FullName : "-"}
                                                                                </td>

                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                    {item.ORGName !== "" ? item.ORGName : "-"}
                                                                                </td>

                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                    {item.DepartmentName !== "" ? item.DepartmentName : "-"}
                                                                                </td>

                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                    {item.DesignationName !== "" ? item.DesignationName : "-"}
                                                                                </td>

                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                    {item.inTime !== "" ? moment(item.in_time).format('DD-MM-YYYY hh:mm A') : "-"}
                                                                                </td>

                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                    {item.BranchName !== "" ? item.BranchName : "-"}
                                                                                </td>
                                                                                <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                    {item.PersMobile !== "" ? item.PersMobile : "-"}
                                                                                </td>


                                                                            </tr>
                                                                        </React.Fragment>
                                                                    ))}

                                                                </React.Fragment>
                                                            )}
                                                        </tbody>

                                                    </Table>


                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                        </div>




                        <Pagination

                            data={data.headcount_logs_count} // Pass logsData to Pagination
                            className="g-0 justify-content-end mb-4"
                            currentPage={currentPage} // Pass currentPage to Pagination
                            setCurrentPage={setCurrentPage} // Pass setCurrentPage to Pagination
                            perPageData={perPageData} // Pass perPageData to Pagination
                        />

                    </Container>
                )}

                {type === 'custom_1' && (
                    <Container fluid>
                        <Row>
                            <Col lg={12}>
                                <Card style={{ border: '2px solid var(--headcount-theme-color)', maxHeight: "calc(100vh - 240px)" }}>
                                    <CardBody>
                                        <div className="table-responsive" style={{
                                            overflow: "auto",
                                            scrollbarWidth: "thin",
                                            maxHeight: "calc(100vh - 272px)"
                                        }}>
                                            <th style={{
                                                textAlign: 'center',
                                                color: 'white',
                                                backgroundColor: '#d56774',
                                                padding: '10px',
                                                textTransform: 'uppercase',
                                                // fontSize: 'var(--table-font-size-default)',
                                                fontSize: `calc(var(--table-font-size-default) + 7px)`,
                                                width: "100%",
                                                display: "block",
                                                paddingBottom: 9,
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 1
                                            }}>{customBlock1Label}</th>

                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingBottom: 10,
                                                paddingTop: 10,
                                            }}>
                                                {customBlock1Image !== null && (
                                                    <img src={customBlock1Image} height="40" width="40" alt="Block 1 Image" />
                                                )}
                                            </div>

                                            {/* Single Table Divided into Three Columns */}
                                            <Table bordered className="table-thin">
                                                <tbody>
                                                    {Array.from(
                                                        { length: Math.ceil(customBlock1TeamData?.length / 3) }
                                                    ).map((_, rowIndex) => {
                                                        // Sort members so `is_in` members come first
                                                        const sortedData = [...customBlock1TeamData].sort((a, b) => {
                                                            return (b?.is_in ? 1 : 0) - (a?.is_in ? 1 : 0);
                                                        });

                                                        const member1 = sortedData[rowIndex * 3];
                                                        const member2 = sortedData[rowIndex * 3 + 1];
                                                        const member3 = sortedData[rowIndex * 3 + 2];

                                                        return (
                                                            <tr key={rowIndex}>
                                                                {/* Member 1 in this row */}
                                                                {member1 && (
                                                                    <>
                                                                        <td style={{
                                                                            padding: '5px',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            paddingLeft: '0.6rem',
                                                                        }}>
                                                                            {member1?.FullName}
                                                                        </td>
                                                                        <td style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: member1?.is_in ? '#1d8958' : '#d56774',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            color: 'white',
                                                                            padding: '5px',
                                                                        }}>
                                                                            {member1?.is_in ? 'In' : 'Out'}
                                                                        </td>
                                                                    </>
                                                                )}

                                                                {/* Member 2 in this row */}
                                                                {member2 && (
                                                                    <>
                                                                        <td style={{
                                                                            padding: '5px',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            paddingLeft: '0.6rem',
                                                                        }}>
                                                                            {member2?.FullName}
                                                                        </td>
                                                                        <td style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: member2?.is_in ? '#1d8958' : '#d56774',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            color: 'white',
                                                                            padding: '5px',
                                                                        }}>
                                                                            {member2?.is_in ? 'In' : 'Out'}
                                                                        </td>
                                                                    </>
                                                                )}

                                                                {/* Member 3 in this row */}
                                                                {member3 && (
                                                                    <>
                                                                        <td style={{
                                                                            padding: '5px',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            paddingLeft: '0.6rem',
                                                                        }}>
                                                                            {member3?.FullName}
                                                                        </td>
                                                                        <td style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: member3?.is_in ? '#1d8958' : '#d56774',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            color: 'white',
                                                                            padding: '5px',
                                                                        }}>
                                                                            {member3?.is_in ? 'In' : 'Out'}
                                                                        </td>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}


                {type === 'custom_2' && (
                    <Container fluid>
                        <Row>
                            <Col lg={12}>
                                <Card style={{ border: '2px solid var(--headcount-theme-color)', maxHeight: "calc(100vh - 240px)" }}>
                                    <CardBody>
                                        <div className="table-responsive" style={{
                                            overflow: "auto",
                                            scrollbarWidth: "thin",
                                            maxHeight: "calc(100vh - 272px)"
                                        }}>
                                            <th style={{
                                                textAlign: 'center',
                                                color: 'white',
                                                backgroundColor: '#1d8958', // Adjusted for Fire Marshals
                                                padding: '10px',
                                                textTransform: 'uppercase',
                                                // fontSize: 'var(--table-font-size-default)',
                                                fontSize: `calc(var(--table-font-size-default) + 7px)`,
                                                width: "100%",
                                                display: "block",
                                                paddingBottom: 9,
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 1
                                            }}>{customBlock2Label}</th>

                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingBottom: 10,
                                                paddingTop: 10,
                                            }}>
                                                {customBlock2Image !== null && (
                                                    <img src={customBlock2Image} height="40" width="40" alt="Block 2 Image" />
                                                )}
                                            </div>

                                            {/* Single Table Divided into Three Columns */}
                                            <Table bordered className="table-thin">
                                                <tbody>
                                                    {/* Mapping over rows with three entries each */}
                                                    {Array.from(
                                                        { length: Math.ceil(customBlock2TeamData?.length / 3) }
                                                    ).map((_, rowIndex) => {
                                                        const sortedData = [...customBlock2TeamData].sort((a, b) => b.is_in - a.is_in);

                                                        // Fetch three members per row
                                                        const member1 = sortedData[rowIndex * 3];
                                                        const member2 = sortedData[rowIndex * 3 + 1];
                                                        const member3 = sortedData[rowIndex * 3 + 2];

                                                        return (
                                                            <tr key={rowIndex}>
                                                                {/* First Column */}
                                                                {member1 ? (
                                                                    <>
                                                                        <td style={{
                                                                            padding: '5px',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            paddingLeft: '0.6rem',
                                                                        }}>
                                                                            {member1?.FullName}
                                                                        </td>
                                                                        <td style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: member1?.is_in ? '#1d8958' : '#d56774',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            color: 'white',
                                                                            padding: '5px',
                                                                        }}>
                                                                            {member1?.is_in ? 'In' : 'Out'}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <td colSpan="2"></td>
                                                                )}

                                                                {/* Second Column */}
                                                                {member2 ? (
                                                                    <>
                                                                        <td style={{
                                                                            padding: '5px',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            paddingLeft: '0.6rem',
                                                                        }}>
                                                                            {member2?.FullName}
                                                                        </td>
                                                                        <td style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: member2?.is_in ? '#1d8958' : '#d56774',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            color: 'white',
                                                                            padding: '5px',
                                                                        }}>
                                                                            {member2?.is_in ? 'In' : 'Out'}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <td colSpan="2"></td>
                                                                )}

                                                                {/* Third Column */}
                                                                {member3 ? (
                                                                    <>
                                                                        <td style={{
                                                                            padding: '5px',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            paddingLeft: '0.6rem',
                                                                        }}>
                                                                            {member3?.FullName}
                                                                        </td>
                                                                        <td style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: member3?.is_in ? '#1d8958' : '#d56774',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            color: 'white',
                                                                            padding: '5px',
                                                                        }}>
                                                                            {member3?.is_in ? 'In' : 'Out'}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <td colSpan="2"></td>
                                                                )}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
                {type === 'birthday_teams_2' && (
                    <Container fluid>
                        <div className="customBlockWrapper" style={{ height: 'auto' }}>
                            <div className="customBlock w-100">

                                <Row>

                                    <Col
                                        //  key={1}
                                        lg={6}>
                                        {/* <Card style={{ border: '1px solid #98a6ad' }} className="table"> */}
                                        <Card style={{ border: '1px solid #98a6ad', overflow: 'hidden', height: 'calc((100vh - 232px) / 2)' }} className="table">
                                            <CardBody style={{ paddingBottom: '1.3rem', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ fontSize: 'var(--headcount-bigcount-font-size-default)', fontWeight: 'bold', lineHeight: '1', padding: '35px 0px 0px 0px', margin: 'auto' }}>{data.in_count_with_visitor}</div>
                                                <div style={{ fontSize: 'var(--headcount-title-font-size-default)', lineHeight: '1', backgroundColor: 'var(--headcount-theme-color)', color: 'white', padding: '15px 0', borderRadius: '2px', fontWeight: '500', marginTop: 'auto' }}>HEADCOUNT</div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col lg={6}>
                                        {/* <Card style={{ border: '2px solid var(--headcount-theme-color)', overflow: 'hidden', height: 'calc((100vh - 232px) / 2)' }} className="table"> */}
                                        <Card
                                            style={{
                                                border: '2px solid var(--headcount-theme-color)',
                                                overflow: 'hidden',
                                                height: 'calc((100vh - 232px) / 2)',
                                            }}
                                            className="table"
                                        >
                                            <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                <div
                                                    className="table-wrapper"
                                                    style={{
                                                        maxHeight: 'calc((100vh - 300px) / 2)',
                                                        overflow: 'auto',
                                                        scrollbarWidth: 'thin',
                                                    }}
                                                >
                                                    <Table
                                                        className="align-middle table-nowrap mb-0 table-scrollable"
                                                        style={{
                                                            fontSize: 'var(--table-font-size-default)',
                                                            backgroundColor: 'var(--scrollable-table-header-color)',
                                                        }}
                                                    >
                                                        <thead
                                                            className="table-scrollable-header"
                                                            style={{ position: 'sticky', top: 0, zIndex: 1 }}
                                                        >
                                                            <tr
                                                                style={{
                                                                    verticalAlign: 'middle',
                                                                    wordWrap: 'wrap',
                                                                    backgroundColor: 'var(--scrollable-table-header-color)',
                                                                }}
                                                            >
                                                                <th
                                                                    scope="col"
                                                                    className="text-wrap"
                                                                    colSpan="2"
                                                                    style={{
                                                                        paddingBottom: '1.7rem',
                                                                        borderBottom: '1px solid var(--headcount-theme-color)',
                                                                        position: 'relative',
                                                                        paddingRight: '100px',
                                                                        color: 'var(--headcount-theme-titles-color)',
                                                                        textTransform: 'uppercase',
                                                                    }}
                                                                >
                                                                    <div style={{ minHeight: '2rem', display: 'flex', alignItems: 'center' }}>
                                                                        {data.block_1_label}
                                                                    </div>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-wrap"
                                                                    style={{
                                                                        paddingBottom: '1.7rem',
                                                                        borderBottom: '1px solid var(--headcount-theme-color)',
                                                                        position: 'relative',
                                                                        paddingRight: '100px',
                                                                        color: 'var(--headcount-theme-titles-color)',
                                                                        textTransform: 'uppercase',
                                                                    }}
                                                                >
                                                                    <div style={{ minHeight: '2rem', display: 'flex', alignItems: 'center' }}>
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '42%',
                                                                                right: '10px',
                                                                                transform: 'translateY(-50%)',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px',
                                                                            }}
                                                                        >
                                                                            {data && data.in_block_1_count}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data &&
                                                                block1TeamData !== 'undefined' &&
                                                                Array.isArray(block1TeamData) &&
                                                                block1TeamData.map((team, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ whiteSpace: 'normal' }}>{team.FullName}</td>
                                                                        <td
                                                                            className="text-start"
                                                                            style={{ color: 'var(--headcount-theme-titles-color)' }}
                                                                        >
                                                                            {team.mx_user_id}
                                                                        </td>
                                                                        <td
                                                                            className="text-start"
                                                                            style={{ color: 'var(--headcount-theme-titles-color)' }}
                                                                        >
                                                                            {team.DepartmentName}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    <Row>

                                    </Row>


                                </Row>

                                <Row>

                                    <Col
                                        // key={index}
                                        lg={6}>
                                        {/* <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table"> */}
                                        <Card style={{ border: '2px solid var(--headcount-theme-color)', overflow: 'hidden', height: 'calc((100vh - 232px) / 2)' }} className="table">
    <CardBody style={{ paddingBottom: '1.7rem' }}>
        <div className="table-wrapper" style={{ maxHeight: 'calc((100vh - 300px) / 2)', overflow: 'auto', scrollbarWidth: 'thin' }}>
            <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'var(--scrollable-table-header-color)' }}>
                    <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}>
                        <th
                            scope="col"
                            colSpan={5}
                            className="text-wrap"
                            style={{
                                paddingBottom: '1.7rem',
                                borderBottom: '1px solid var(--headcount-theme-color)',
                                position: 'relative',
                                paddingRight: '100px',
                                color: 'var(--headcount-theme-titles-color)',
                                textTransform: 'uppercase'
                            }}
                        >
                            <div style={{ minHeight: '2rem', display: 'flex', alignItems: 'center' }}>
                                {data.birthday_teams_2_label}
                            </div>
                            <span
                                className="text-center"
                                style={{
                                    position: 'absolute',
                                    top: '42%',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'var(--headcount-theme-color)',
                                    color: 'white',
                                    padding: '5px 18px',
                                    borderRadius: '10px'
                                }}
                            >
                                {data.birthday_teams_2_count}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.birthday_teams_2 && data?.birthday_teams_2 !== 'undefined' && data?.birthday_teams_2.map((team, index) => (
                        <tr key={index}>
                            <td className="" style={{ width: '65%', whiteSpace: 'normal' }}>{team.FullName}</td>
                            <td className="text-start" style={{ color: 'var(--headcount-theme-titles-color)' }}> {team.DepartmentName} </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    </CardBody>
</Card>

                                    </Col>

                                    <Col
                                        key={2}
                                        lg={6}
                                    >
                                        <Card
  style={{
    border: '2px solid var(--headcount-theme-color)',
    height: 'calc((100vh - 232px) / 2)',
  }}
  className="table"
>
  <CardBody style={{ paddingBottom: '1.7rem' }}>
    <div
      className="table-wrapper"
      style={{
        maxHeight: 'calc((100vh - 300px) / 2)',
        overflow: 'auto',
        scrollbarWidth: 'thin',
      }}
    >
      <Table
        className="align-middle table-nowrap mb-0"
        style={{
          fontSize: 'var(--table-font-size-default)',
          width: '100%',
        }}
      >
        {/* Fixed Table Header */}
        <thead
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'var(--scrollable-table-header-color)',
          }}
        >
          <tr>
            <th
              scope="col"
              colSpan={2}
              style={{
                paddingBottom: '1.7rem',
                borderBottom: '1px solid var(--headcount-theme-color)',
                paddingRight: '100px',
                color: 'var(--headcount-theme-titles-color)',
                textTransform: 'uppercase',
              }}
            >
              <div
                style={{
                  minHeight: '2rem',
                  display: 'flex',
                  alignItems: 'center', // Centers content vertically
                }}
              >
                {data.block_2_label}
              </div>
            </th>
            <th
              scope="col"
              colSpan={2}
              style={{
                paddingBottom: '1.7rem',
                borderBottom: '1px solid var(--headcount-theme-color)',
                paddingRight: '100px',
                color: 'var(--headcount-theme-titles-color)',
                textTransform: 'uppercase',
              }}
            >
              <div
                style={{
                  minHeight: '2rem',
                  display: 'flex',
                  alignItems: 'center', // Centers content vertically
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    top: '42%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'var(--headcount-theme-color)',
                    color: 'white',
                    padding: '5px 18px',
                    borderRadius: '10px',
                  }}
                >
                  {data?.in_block_2_count}
                </span>
              </div>
            </th>
          </tr>
        </thead>

        {/* Scrollable Table Body */}
        <tbody>
          {data &&
            block2TeamData !== 'undefined' &&
            Array.isArray(block2TeamData) &&
            block2TeamData?.map((team, index) => (
              <tr key={index}>
                <td style={{ whiteSpace: 'normal' }}>{team.FullName}</td>
                <td
                  className="text-start"
                  style={{ color: 'var(--headcount-theme-titles-color)' }}
                >
                  {team.mx_user_id}
                </td>
                <td
                  className="text-start"
                  style={{ color: 'var(--headcount-theme-titles-color)' }}
                >
                  {team.DepartmentName}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  </CardBody>
</Card>

                                    </Col>


                                </Row>

                            </div>
                        </div>
                    </Container>
                )}



            </div>
        </React.Fragment >
    );
};

export default ViewDetail;
